import NoSSR from "components/shared/NoSSR"
import { USER_TYPE } from "utils/constants"
import { getPlatformURL } from "utils/helpers"

const IframeSSO = () => (
  <NoSSR>
    {typeof window !== "undefined" ? (
      <iframe
        aria-hidden={false}
        src={getPlatformURL(
          "sso",
          `/iframe-sso?platform=${USER_TYPE}&origin=${window.location.origin}`
        )}
        style={{ display: "none" }}
        title="web-accounts"
      />
    ) : null}
  </NoSSR>
)

export default IframeSSO
