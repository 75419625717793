import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore"

import { Country } from "api/resources/global/types"
import { db } from "utils/firebaseInit"

import { combineArraysRandomly } from "../utils"

export type RealTimeAlertUser = {
  id: string
  name: string
  location: string
  timestamp: number
  isOutsideIndia: boolean
}

export enum CollectionName {
  ito = "ito2023RealtimeRegistrations",
  ttm = "talkToMentorRealtimeRegistrations",
}
export const getItoRealtimeRegisteredUsers = async (
  country: Country | null
) => {
  const isOutsideIndia = !(country && country.name === "India")

  const docRef = collection(db, CollectionName.ito)

  /**
   * If user is inside India, we want to emphasize Indian teachers more
   * If user is outside India, we want to emphasize International teachers more
   */
  const indianUsers = await getDocs(
    query(
      docRef,
      where("isOutsideIndia", "in", [false, null]),
      orderBy("timestamp", "desc"),
      limit(isOutsideIndia ? 2 : 5)
    )
  )
  const nonIndianUsers = await getDocs(
    query(
      docRef,
      where("isOutsideIndia", "==", true),
      orderBy("timestamp", "desc"),
      limit(isOutsideIndia ? 8 : 5)
    )
  )

  const indiaData = indianUsers.docs.map(
    document => document.data() as RealTimeAlertUser
  )
  const outsideIndiaData = nonIndianUsers.docs.map(
    document => document.data() as RealTimeAlertUser
  )

  return combineArraysRandomly(outsideIndiaData, indiaData)
}

export const getTTMRealtimeRegisteredUsers = async (
  country: Country | null
) => {
  const isOutsideIndia = !(country && country.name === "India")

  const docRef = collection(db, CollectionName.ito)

  /**
   * If user is inside India, we want to emphasize Indian teachers more
   * If user is outside India, we want to emphasize International teachers more
   */
  const indianUsers = await getDocs(
    query(
      docRef,
      where("isOutsideIndia", "in", [false, null]),
      orderBy("timestamp", "desc"),
      limit(isOutsideIndia ? 2 : 5)
    )
  )
  const nonIndianUsers = await getDocs(
    query(
      docRef,
      where("isOutsideIndia", "==", true),
      orderBy("timestamp", "desc"),
      limit(isOutsideIndia ? 8 : 5)
    )
  )

  const indiaData = indianUsers.docs.map(
    document => document.data() as RealTimeAlertUser
  )
  const outsideIndiaData = nonIndianUsers.docs.map(
    document => document.data() as RealTimeAlertUser
  )

  return combineArraysRandomly(outsideIndiaData, indiaData)
}

export const logRegistrationOnFirebase = async (
  data: RealTimeAlertUser,
  collectionName: CollectionName
) => {
  const docRef = doc(db, collectionName, data.id)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return
  }
  setDoc(docRef, data)
  return null
}

// export const updateRegistrationOnFirebase = async (data: RealTimeAlertUser) => {
//   const docRef = doc(db, COLLECTION_NAME, data.id)
//   const docSnap = await getDoc(docRef)
//   if (docSnap.exists()) {
//     console.info("Logging on Firebase", data)
//     setDoc(docRef, data)
//     return null
//   }
//   return null
// }
