/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react"

import { Avatar, Container, Typography } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: "#F1FAFA",
    padding: theme.spacing(8, 0),
  },
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  testimonialContainer: {
    overflow: "hidden",
    minHeight: 236,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },

  "@keyframes marquee": {
    from: {
      transform: "translate3d(0% , 0, 0)",
    },

    to: {
      transform: "translate3d(-50%, 0, 0)",
    },
  },
  sliderContainer: {
    position: "relative",
    height: 265,
  },

  slider: {
    padding: theme.spacing(1, 0),
    position: "absolute",
    top: 0,
    left: 0,
    overflow: "hidden",

    "& .slider-inner": {
      "&:hover": {
        animationPlayState: "paused",
      },
      width: "max-content",
      display: "flex",
      position: "relative",
      animation: "$marquee 60s linear infinite",
    },
  },
}))

const data = [
  {
    name: "Sikhanyiso Maviza Loveness",
    image: "/assets/landing_page/testimonials/Sikhanyiso Maviza Loveness.webp",
    designation: "Early Intervention Teacher, Dubai, United Arab Emirates",
    quote:
      "I realized that the future of education lies in seeing every student as unique and accepting them fully.",
  },
  {
    name: "Ashwin James Lal",
    image: "/assets/landing_page/testimonials/Ashwin James Lal.webp",
    designation: "World History Teacher, The British School, New Delhi",
    quote:
      "PgCTL bridges the gap in teacher training, providing resources and strategies to meet 21st-century classroom challenges.",
  },
  {
    name: "Ritu Sahni",
    image: "/assets/landing_page/testimonials/Ritu Sahni.webp",
    designation: "PGT English Teacher",
    quote:
      "PgCTL was a game-changer for me, giving me deeper insights into pedagogy, classroom management, and effective teaching strategies.",
  },
  {
    name: "Sonu Soni",
    image: "/assets/landing_page/testimonials/Sonu Soni.webp",
    designation: "Chemistry Teacher",
    quote:
      "I seriously believe it’s all because of Suraasa and the team that I got this opportunity.",
  },
  {
    name: "Saima Afroz",
    image: "/assets/landing_page/testimonials/Saima Afroz.webp",
    designation: "Teacher at Union Elementary School District, AZ",
    quote:
      "PgCTL completely changed my approach to teaching and gave me the confidence I needed in the classroom.",
  },
  {
    name: "Wajiha Aamir",
    image: "/assets/landing_page/testimonials/Wajiha Aamir.webp",
    designation: "Head of Cycle KS1 & KS2",
    quote:
      "Suraasa didn’t leave me midway; they supported me even after I completed the program.",
  },
  {
    name: "Reema Burhan",
    image: "/assets/landing_page/testimonials/Reema Burhan.webp",
    designation: "HOD Mathematics",
    quote:
      "I’m so grateful because it really helped me, and because of that, I’ve even been able to find a better job.",
  },
  {
    name: "Nemanja Dordevic",
    image: "/assets/landing_page/testimonials/Nemanja Dordevic.webp",
    designation: "HOD Science",
    quote:
      "PgCTL equipped me with the crucial tools to meet the demands of today’s teaching market.",
  },
  {
    name: "Amala R",
    image: "/assets/landing_page/testimonials/Amala R.webp",
    designation: "English Teacher",
    quote:
      "Suraasa equips you with advanced insights and empowers you to be a well-prepared teacher in today’s competitive world.",
  },
  {
    name: "Shaikh Abdulla",
    image: "/assets/landing_page/testimonials/Shaikh Abdulla.webp",
    designation: "Lead Lab Technician",
    quote:
      "PgCTL allows me to be recognized internationally as a teacher, complementing my career in the medical field.",
  },
  {
    name: "Ghousia Tasneem Hashmi",
    image: "/assets/landing_page/testimonials/Ghousia Tasneem Hashmi.webp",
    designation: "Geography and Social studies Teacher",
    quote:
      "PgCTL has given me a fallback plan, so if one strategy doesn’t work, I have others ready.",
  },
  {
    name: "Sivasundari Vijayakumar",
    image: "/assets/landing_page/testimonials/Sivasundari Vijayakumar.webp",
    designation: "Math Teacher",
    quote:
      "Suraasa's support took me from a place of uncertainty to a place where I now feel valued and confident in my career.",
  },
  {
    name: "Baishali Nandi",
    image: "/assets/landing_page/testimonials/Baishali Nandi.webp",
    designation: "AS A Level Business Studies Teacher",
    quote:
      "Teaching has always been a passion for me, and PgCTL gave me the pathway to finally chase it.",
  },
  {
    name: "Jeslin Sara",
    image: "/assets/landing_page/testimonials/Jeslin Sara.webp",
    designation: "Science Teacher",
    quote:
      "The amount of learning that I received was immense; it even helps boost your confidence.",
  },
  {
    name: "Shreya",
    image: "/assets/landing_page/testimonials/Shreya.webp",
    designation: "Primary Teacher",
    quote:
      "When a bond is created between a teacher and a student, true learning happens.",
  },
  {
    name: "Pawan Bisht",
    image: "/assets/landing_page/testimonials/Pawan Bisht.webp",
    designation: "Physics & Math Teacher",
    quote:
      "Teaching is not just about the subject; it's about connecting with students and making learning a positive experience.",
  },
  {
    name: "Jayavardhan Reddy",
    image: "",
    designation: "Maths Facilitator",
    quote:
      "The online classes with Suraasa helped me shift to a student-centric approach, enhancing both my teaching style and interview readiness.",
  },
  {
    name: "Karuna Lobo",
    image: "/assets/landing_page/testimonials/Karuna Lobo.webp",
    designation: "Secondary Teacher",
    quote:
      "With Suraasa, I learned to create structured lesson plans that truly cater to students’ cognitive levels.",
  },
  {
    name: "Monica Srivastava",
    image: "/assets/landing_page/testimonials/Monica Srivastava.webp",
    designation: "Facilitator & Coordinator",
    quote:
      "Teaching should prepare students for the future by integrating critical thinking, collaboration, and 21st-century skills.",
  },
  {
    name: "Aiswarya Das",
    image: "/assets/landing_page/testimonials/Aiswarya Das.webp",
    designation: "Math Teacher",
    quote:
      "This course was a life-changing experience, helping me turn theoretical knowledge into practical skills I now apply in my classroom.",
  },
  {
    name: "Priyanshu Malik",
    image: "/assets/landing_page/testimonials/Priyanshu Malik.webp",
    designation: "Middle and Senior School Teacher",
    quote:
      "With Suraasa’s training, I moved from simply knowing theories to actually implementing them effectively in the classroom.",
  },
  {
    name: "Haripriya",
    image: "/assets/landing_page/testimonials/Haripriya.webp",
    designation: "KG2 Homeroom Teacher",
    quote:
      "The PgCTL training were a transformation, helping me see every student’s unique potential and how best to support it.",
  },
  {
    name: "Artheeta Sarker",
    image: "/assets/landing_page/testimonials/Artheeta Sarker.webp",
    designation: "English Teacher",
    quote:
      "When I did my Suraasa course, I was able to link the theory with the practical things I was doing in school.",
  },
] as const

const TeacherTestimonials = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.center}>
          <Typography
            className="mb-2"
            style={{ maxWidth: 640 }}
            textAlign="center"
            variant="title1"
          >
            Teachers Trained by Suraasa Reach Newer Heights. Every Day.
          </Typography>

          <Typography
            style={{ maxWidth: 554 }}
            textAlign="center"
            variant="largeBody"
          >
            Our teachers from 50+ nationalities continue to receive promotions,
            salary hikes, and recognition.
          </Typography>
        </div>
      </Container>

      <div className={classes.testimonialContainer}>
        <div className={classes.sliderContainer}>
          <div className={classes.slider}>
            <div className="slider-inner">
              {data.map((d, i) => (
                <Card {...d} key={i} />
              ))}
              {data.map((d, i) => (
                <Card {...d} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const useCardStyles = createUseStyles(theme => ({
  root: {
    marginRight: theme.spacing(2.5),
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    backgroundColor: "white",
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    maxWidth: "354px",

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  quote: {
    fontFamily: "Lora",
  },
}))

const Card = ({ quote, name, image, designation }: typeof data[number]) => {
  const classes = useCardStyles()

  return (
    <div className={classes.root}>
      <Typography className={clsx(classes.quote, "mb-4")} variant="largeBody">
        “{quote}”
      </Typography>

      <div className="flex items-end">
        <div className="mr-2 shrink-0">
          <Avatar name={name} src={image} />
        </div>

        <div>
          <Typography className="mb-0.25">{name}</Typography>
          <Typography variant="strong">{designation}</Typography>
        </div>
      </div>
    </div>
  )
}

export default TeacherTestimonials
