/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react"

import { Avatar, Container, Typography } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: "white",
    padding: theme.spacing(8, 0),
  },
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}))

const PrincipalTestimonials = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.center}>
          <Typography
            className="mx-auto mb-1"
            style={{ maxWidth: 681 }}
            textAlign="center"
            variant="title2"
          >
            Hear from Top Schools employing Suraasa Teachers
          </Typography>
          <Typography
            className="mx-auto mb-3"
            style={{ maxWidth: 554 }}
            textAlign="center"
            variant="largeBody"
          >
            Suraasa teachers continue winning trust & respect of schools and
            children alike
          </Typography>
        </div>

        <div className="flex flex-wrap justify-center gap-3">
          {data.map(d => (
            <Card {...d} key={d.name} />
          ))}
        </div>
      </Container>
    </div>
  )
}

const data = [
  {
    img: "/assets/landing_page/testimonials/principals/Humera-min.webp",
    quote:
      "This course will give you the concrete preparation to understand the profession.",
    name: "Ms Humera A.",
    designation: "Principal of a Leading School British Curriculum",
    theme: "one",
  },
  {
    img: "/assets/landing_page/testimonials/principals/DrFernandes-min.webp",
    quote:
      "Choose Suraasa we will be happy to hire you. I see the positivity & satisfaction in my teachers now.",
    name: "Dr Frank F.",
    designation: "Principal of a Leading School British Curriculum",
    theme: "two",
  },
  {
    img: "/assets/landing_page/testimonials/principals/Murphy-min.webp",
    quote:
      "If I receive an application from a teacher who has done PgCTL, I’d 8/10 invite them for an interview.",
    name: "Mr. Murphy",
    designation: "Principal of a Leading School British Curriculum",
    theme: "four",
  },
] as const

const useCardStyles = createUseStyles(theme => ({
  root: {
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    backgroundColor: "white",
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    maxWidth: "354px",

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  quote: {
    fontFamily: "Lora",
    fontWeight: "500 !important",
    marginBottom: theme.spacing(4),
  },
  one: {
    backgroundColor: theme.colors.decorative.one[50],
  },
  two: {
    backgroundColor: theme.colors.decorative.two[50],
  },
  four: {
    backgroundColor: theme.colors.decorative.four[50],
  },
}))

const Card = ({
  quote,
  name,
  img,
  designation,
  theme,
}: typeof data[number]) => {
  const classes = useCardStyles()

  return (
    <div className={clsx(classes[theme], classes.root)}>
      <Typography className={classes.quote}>“{quote}”</Typography>

      <div className="flex">
        <div className="mr-2 shrink-0">
          <Avatar name={name} src={img} />
        </div>

        <div>
          <Typography variant="strongSmallBody">{name}</Typography>
          <Typography variant="smallBody">{designation}</Typography>
        </div>
      </div>
    </div>
  )
}

export default PrincipalTestimonials
