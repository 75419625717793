import React from "react"

import { Container, Typography } from "@suraasa/placebo-ui-legacy"
import { createUseStyles } from "react-jss"

import { useGradientStyle } from "components/shared/GradientTypography"

import CTAButton from "./shared/CTAButton"

const useStyles = createUseStyles(theme => ({
  root: {
    padding: theme.spacing(8, 0),
    backgroundColor: "white",
  },
  content: {
    backgroundColor: theme.colors.onSurface[100],
    padding: theme.spacing(3, 8),
    borderRadius: theme.spacing(2),

    flexWrap: "wrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4, 4),
      flexDirection: "column",
    },
  },
}))

const ExcitedAboutSuraasa = () => {
  const classes = useStyles()

  const gradient = useGradientStyle(
    " linear-gradient(90deg, #9A7BBA 0%, #E5A256 100%)"
  )

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.content}>
          <Typography style={{ maxWidth: 336 }} variant="title2">
            Become a <span style={gradient}>Great Teacher</span> Under the
            Guidance of Many.
          </Typography>

          <CTAButton
            className="mt-3 lg:mt-0"
            tagClassName="text-secondary-600 mx-auto md:mx-0"
          />
        </div>
      </Container>
    </div>
  )
}

export default ExcitedAboutSuraasa
