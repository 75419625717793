import React from "react"

import { Container, Typography } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image from "next/image"
import Arrows from "public/assets/landing_page/v2/dreams/arrows.json"
import Earths from "public/assets/landing_page/v2/dreams/earths.json"
import Hearts from "public/assets/landing_page/v2/dreams/hearts.json"
import Stars from "public/assets/landing_page/v2/dreams/stars.json"
import StaticArrows from "public/assets/landing_page/v2/dreams/staticAnimations/arrows.svg"
import StaticEarths from "public/assets/landing_page/v2/dreams/staticAnimations/earths.svg"
import StaticHearts from "public/assets/landing_page/v2/dreams/staticAnimations/hearts.svg"
import StaticStars from "public/assets/landing_page/v2/dreams/staticAnimations/stars.svg"
import { createUseStyles } from "react-jss"

import { useGradientStyle } from "components/shared/GradientTypography"

import Lottie from "./DynamicImports/Lottie"

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: "white",
    padding: theme.spacing(8, 0),
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: 24,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  cardContainer: {
    overflow: "hidden",
    borderRadius: 16,
    position: "relative",
    "&.heart": {
      background: theme.colors.decorative.one[50],
      color: theme.colors.decorative.one[200],
    },
    "&.arrow": {
      background: theme.colors.decorative.two[50],
      color: theme.colors.decorative.two[200],
    },
    "&.earth": {
      background: theme.colors.decorative.three[50],
      color: theme.colors.decorative.three[200],
    },
    "&.star": {
      background: theme.colors.decorative.four[50],
      color: theme.colors.decorative.four[200],
    },
  },
  title: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "29px",
    letterSpacing: "-0.02em",
    display: "block",
    marginBottom: 12,
  },
  description: {
    maxWidth: 379,
    width: "100%",
    marginBottom: 98,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 46,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 132,
    },
  },
  animation: {
    position: "absolute",
    bottom: -4,
    right: 0,
    "&.earth": {
      bottom: -30,
    },
    "&.star": {
      bottom: -16,
    },
    [theme.breakpoints.down("sm")]: {
      width: 185,
    },
  },
}))

const defaultOptions = {
  loop: true,
  autoplay: true,
}
type CardProps = {
  title: string
  description: string
  cardClass: string
  animation: any
  staticAsset: any
}

const Card = (
  data: CardProps & {
    isMobile: boolean
  }
) => {
  const { title, description, cardClass, animation, isMobile, staticAsset } =
    data
  const classes = useStyles()

  return (
    <div className={clsx("p-2.5 sm:p-4", classes.cardContainer, cardClass)}>
      <span className={clsx(classes.title)}>{title}</span>
      <Typography className={classes.description} variant="largeBody">
        {description}
      </Typography>
      <div className={clsx(classes.animation, cardClass)}>
        {isMobile ? (
          <Image
            alt={title}
            className="object-contain"
            src={staticAsset}
            width={185}
          />
        ) : (
          <Lottie
            options={{ ...defaultOptions, animationData: animation }}
            speed={1.25}
          />
        )}
      </div>
    </div>
  )
}

const CloserToDreams = ({ isMobile }: { isMobile: boolean }) => {
  const classes = useStyles()

  const gradient = useGradientStyle(
    "linear-gradient(90deg, #49B7CC 0%, #97BC62 100%)"
  )
  const cards: CardProps[] = [
    {
      title: "Love Teaching",
      description:
        "Learn teaching strategies that make teaching joyful and impactful. Your students will fall in love with learning from you.",
      cardClass: "heart",
      animation: Hearts,
      staticAsset: StaticHearts,
    },
    {
      title: "Grow and Earn more",
      description:
        "Work towards your career growth with guidance from experts. Bag the highest salary packages available for teachers.",
      cardClass: "arrow",
      animation: Arrows,
      staticAsset: StaticArrows,
    },
    {
      title: "Get Ready for the World",
      description:
        "Add internally recognised certifications to your profile. Be prepared for any classroom, any grade, any country in the world.",
      cardClass: "earth",
      animation: Earths,
      staticAsset: StaticEarths,
    },
    {
      title: "Be In Demand Everywhere",
      description:
        "Gain skills the best schools internationally desire in their teachers. Enjoy the wave of interview calls and job offers.",
      cardClass: "star",
      animation: Stars,
      staticAsset: StaticStars,
    },
  ]

  return (
    <div className={classes.root}>
      <Container>
        <div className="flex flex-col items-center">
          <Typography
            className="mb-2"
            style={{ maxWidth: 681 }}
            textAlign="center"
            variant="title1"
          >
            Closer Everyday to Your{" "}
            <span style={gradient}>
              <span>Dreams</span>
            </span>
          </Typography>

          <Typography
            className="mb-4 md:mb-6 sm:mb-5"
            color="onSurface.600"
            style={{ maxWidth: 698 }}
            textAlign="center"
            variant="subtitle1"
          >
            Suraasa's teaching programs lead you to your desired success with a
            blend of expert mentorship, guided pedagogy training, and
            employability support.
          </Typography>
        </div>
        <div className={classes.gridContainer}>
          {cards.map((card, i) => (
            <Card {...card} isMobile={isMobile} key={i} />
          ))}
        </div>
      </Container>
    </div>
  )
}

export default CloserToDreams
