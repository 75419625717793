import React from "react"

import {
  Container,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import Image from "next/image"
import { createUseStyles, useTheme } from "react-jss"

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: theme.colors.onSurface[50],
    padding: theme.spacing(8, 0),
  },
  width: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

const GloballyAccepted = () => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div className={classes.root}>
      <Container>
        <div className="flex flex-col items-center justify-center md:justify-between md:flex-row">
          <div className={classes.width}>
            <Typography
              textAlign={isSmDown ? "center" : "left"}
              variant="title2"
            >
              Globally Accepted
            </Typography>
            <Typography
              className="mt-0.5"
              color="onSurface.600"
              textAlign={isSmDown ? "center" : "left"}
              variant="largeBody"
            >
              International schools trust Suraasa teaching qualifications as a
              global standard. Suraasa's courses & qualifications are accredited
              by international bodies and accepted globally.{" "}
            </Typography>
          </div>
          <div className="flex flex-wrap items-end gap-3 mt-4 md:mt-0 sm:gap-0">
            <div className="mr-5">
              <Image
                alt="athe"
                height={55}
                src="/assets/landing_page/v2/athe-logo.webp"
                width={167}
              />
            </div>
            <div className="mr-5">
              <Image
                alt="ofqual"
                height={55}
                src="/assets/landing_page/v2/ofequal-logo.webp"
                width={105}
              />
            </div>
            <Image
              alt="nations"
              height={70}
              src="/assets/landing_page/v2/nations-logo.webp"
              width={113}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default GloballyAccepted
