import { useEffect, useRef, useState } from "react"

import metadata from "metadata.json"
import { GetServerSideProps } from "next"
import { useRouter } from "next/router"
import { getSelectorsByUserAgent } from "react-device-detect"

import api from "api"
import {
  Country,
  CourseItem,
  QualificationItem,
} from "api/resources/learn/types"
import Footer from "components/Footer"
import AiTools from "components/landingPage/AiTools"
import CloserToDreams from "components/landingPage/CloserToDreams"
import CpdSection from "components/landingPage/CpdSection"
import Educators from "components/landingPage/Educators"
import ExcitedAboutSuraasa from "components/landingPage/ExcitedAboutSuraasa"
import FAQs from "components/landingPage/FAQs"
import FreeResources from "components/landingPage/FreeResources"
import GloballyAccepted from "components/landingPage/GloballyAccepted"
import HeroSection from "components/landingPage/HeroSection"
import MagicHappens from "components/landingPage/MagicHappens"
import PgctlSection from "components/landingPage/PgctlSection"
import PrincipalTestimonials from "components/landingPage/PrincipalTestimonials"
import SchoolPartners from "components/landingPage/SchoolPartners"
import StickyCTAButton from "components/landingPage/StickyCTAButton"
import TalkToAMentorSection from "components/landingPage/TalkToAMentorSection"
import TeacherTestimonials from "components/landingPage/TeacherTestimonials"
import Navbar from "components/Navbar"
import IframeSSO from "components/shared/IframeSSO"
import Metadata from "components/shared/metadata"
import NoSSR from "components/shared/NoSSR"
import RealtimeAlerts from "components/talkToAMentor/RealtimeAlerts"

type InitialProps = {
  country: Country["isoCode"] | null
  courses: CourseItem[]
  qualifications: QualificationItem[]
}

type ServerSideProps = {
  deviceProps: {
    isMobile: boolean
  }
}

function Index({ deviceProps: { isMobile } }: ServerSideProps) {
  const ref = useRef({
    lastScroll: { y: 0 },
    hasScrolled: false,
  })

  const [initialValues, setInitialValues] = useState<InitialProps>({
    country: null,
    courses: [],
    qualifications: [],
  })
  const router = useRouter()
  const { lp } = router.query
  const isAdvertisementPage = (Array.isArray(lp) ? lp[0] : lp ?? "") === "1"

  useEffect(() => {
    ;(async () => {
      const params = { category_slug: "landing-page" }

      const [qualificationRes, courseRes, countryRes] = await Promise.all([
        api.learn.getQualifications({ params }),
        api.learn.getCourses({ params }),
        api.learn.getCountry({}),
      ])

      const qualifications = qualificationRes.isSuccessful
        ? qualificationRes.data
        : []

      const courses = courseRes.isSuccessful ? courseRes.data : []

      setInitialValues({
        country: countryRes.isSuccessful
          ? countryRes.data?.isoCode ?? null
          : null,
        courses,
        qualifications,
      })
    })()
  }, [])

  // useEffect(() => {
  //   const getPercentageScrolled = throttle(() => {
  //     try {
  //       if (ref.current) {
  //         const currentScrollPosition = window.scrollY

  //         const data = ref.current

  //         if (currentScrollPosition < data.lastScroll.y && !data.hasScrolled) {
  //           const { body } = document
  //           const html = document.documentElement

  //           const pageHeight = Math.max(
  //             body.scrollHeight,
  //             body.offsetHeight,
  //             html.clientHeight,
  //             html.scrollHeight,
  //             html.offsetHeight
  //           )

  //           const scrollPercentage = Math.min(
  //             Math.round((currentScrollPosition / pageHeight) * 100),
  //             100
  //           )

  //           // For debugging purpose
  //           // console.log(`Triggering SCROLL_UP with ${scrollPercentage}%.`)

  //           data.hasScrolled = true
  //         } else if (currentScrollPosition > data.lastScroll.y) {
  //           data.hasScrolled = false
  //         }

  //         data.lastScroll.y = currentScrollPosition
  //       }
  //     } catch {
  //       // We don't actually want to do anything here
  //     }
  //   }, 500)

  //   window.addEventListener("scroll", getPercentageScrolled)
  //   return () => {
  //     window.removeEventListener("scroll", getPercentageScrolled)
  //   }
  // }, [])

  const { qualifications, country, courses } = initialValues

  return (
    <>
      <Metadata data={metadata.mainPage}>
        {/* eslint-disable-next-line @next/next/no-page-custom-font */}
        <link
          href="https://fonts.googleapis.com/css2?family=Lora:wght@500&display=swap"
          rel="stylesheet"
        />
        <link href="https://suraasa.com" hrefLang="x-default" rel="alternate" />
        <link
          href="https://suraasa.com/teacher-recruitment"
          hrefLang="en-us"
          rel="alternate"
        />
      </Metadata>
      <IframeSSO />
      <RealtimeAlerts />
      {isAdvertisementPage && <StickyCTAButton />}
      <Navbar gutterBottom={false} isAdvertisementPage={isAdvertisementPage} />
      <HeroSection />
      <SchoolPartners country={country} />
      <TeacherTestimonials />
      {Boolean(qualifications) && qualifications.length > 0 && (
        <PgctlSection data={qualifications[0]} />
      )}
      <CloserToDreams isMobile={isMobile} />
      <GloballyAccepted />
      <TalkToAMentorSection />
      <PrincipalTestimonials />
      <MagicHappens />
      <NoSSR>
        <AiTools />
      </NoSSR>
      {!isAdvertisementPage && (
        <>
          {Boolean(courses) && courses.length > 0 && (
            <CpdSection courses={courses} />
          )}
          <FreeResources />
        </>
      )}
      <Educators />
      <ExcitedAboutSuraasa />
      {/* DOUBT: why is FAQ giving hydration error without NoSSR */}
      <NoSSR>
        <FAQs isAdvertisementPage={isAdvertisementPage} />
      </NoSSR>
      {/* <Stats /> */}
      {!isAdvertisementPage && <Footer showBookCall />}
    </>
  )
}

export const getServerSideProps: GetServerSideProps<ServerSideProps> = async ({
  req,
  // eslint-disable-next-line @typescript-eslint/require-await
}) => {
  // const ip = req.headers["x-forwarded-for"]
  // const headers = { "x-forwarded-for": `${ip}` }

  const userAgent = req.headers["user-agent"] || ""
  const { isMobile } = getSelectorsByUserAgent(userAgent) || {}

  // const [countryRes] = await Promise.all([
  //   api.learn.getCountry(ip ? { headers } : {}),
  // ])

  // const countryIsoCode = countryRes.isSuccessful
  //   ? countryRes.data.isoCode
  //   : null

  // Redirect to /teacher-recruitment if country is US
  // if (countryIsoCode === "US") {
  //   return {
  //     redirect: {
  //       destination: "/teacher-recruitment",
  //       permanent: false,
  //     },
  //   }
  // }

  return {
    props: {
      deviceProps: {
        isMobile,
      },
    },
  }
}

export default Index
