/* eslint-disable @next/next/no-img-element */
import React from "react"

import {
  Container,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { createUseStyles, useTheme } from "react-jss"

import { Check } from "iconoir-react"

import CTAButton from "./shared/CTAButton"

const useStyles = createUseStyles(theme => ({
  root: {
    width: "100%",
    height: "480px",
    overflow: "hidden",
    position: "relative",
    background: theme.colors.primary[50],
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },

  container: {
    height: "100%",
    zIndex: 1,
    position: "relative",
  },
  text: {
    width: "50%",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "688px",
      height: "auto",
      "& > h2": {
        textAlign: "center",
      },
    },
  },
  coverImg: {
    maxHeight: "421px",
    maxWidth: "514px",
    position: "absolute",
    bottom: 0,
    right: 0,

    [theme.breakpoints.down("lg")]: {
      maxHeight: "370px",
      maxWidth: "425px",
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      maxHeight: "245px",
      maxWidth: "300px",
    },
  },
}))

function HeroSection() {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isSm = useMediaQuery(theme.breakpoints.only("sm"))

  return (
    <div className={clsx(classes.root)}>
      <Container className={clsx(classes.container)}>
        <div
          className={clsx(
            classes.container,
            "flex items-center md:justify-between flex-col pt-7 gap-5 md:flex-row md:pt-0 md:gap-4"
          )}
        >
          <div
            className={clsx(
              classes.text,
              "flex flex-col justify-center md:items-start sm:items-center items-start"
            )}
          >
            <Typography className="mb-1" component="h1" variant="title1">
              Upskill to Teach at Top{!isSm ? <br /> : " "}
              International Schools
            </Typography>
            <div className="flex flex-col">
              {[
                "Get high-paying teaching jobs & faster promotions",
                "Learn skills to impact all your students equally",
                "Enrol into international qualifications and certifications",
              ].map((item, i) => (
                <div className="flex items-center mt-1" key={i}>
                  <Check
                    className="mr-0.25"
                    color={theme.colors.primary[500]}
                  />
                  <Typography
                    component="h2"
                    textAlign={isSm ? "center" : "left"}
                    variant="subtitle2"
                  >
                    {item}
                  </Typography>
                </div>
              ))}
            </div>
            <CTAButton
              className="self-start mt-4 md:self-start sm:self-center"
              tagClassName="text-secondary-600 mx-0 sm:mx-auto md:mx-0"
            />
            {/* <div className="self-start mt-3 md:self-start sm:self-center">
              <Typography color="onSurface.800" variant="smallBody">
                World's First Career Growth Platform for Educators
              </Typography>
              <div className="flex justify-start mt-2 sm:justify-center md:justify-start">
                <img
                  alt="logo"
                  className="mr-4"
                  height="48px"
                  src="assets/homepage/v2/hero-logo-1.webp"
                  width="120px"
                />
                <img
                  alt="logo"
                  height="48px"
                  src="assets/homepage/v2/hero-logo-2.webp"
                  width="89px"
                />
              </div>
            </div> */}
          </div>
          <img
            alt="girl"
            className={classes.coverImg}
            src="/assets/landing/learning/cover.webp"
          />
        </div>
      </Container>
    </div>
  )
}

export default HeroSection
