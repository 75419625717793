import React from "react"

import { Container, Typography } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image from "next/image"
import { createUseStyles } from "react-jss"

import { Calendar, Clock, GraphUp } from "iconoir-react"

import { QualificationItem } from "api/resources/learn/types"
import GradientText from "components/shared/GradientTypography"

import CTAButton from "./shared/CTAButton"

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: theme.colors.onSurface[900],
    color: "white",
    padding: theme.spacing(8, 0),
  },
  iconBox: {
    background: "linear-gradient(90deg, #60A5FA 0%, #3B82F6 100%)",
    backdropFilter: "blur(17.5px)",
    borderRadius: 8,
    height: 40,
    width: 40,
  },
  textBody: {
    maxWidth: "70%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  bookACall: {
    background: "linear-gradient(90deg, #60A5FA 0%, #3B82F6 100%)",
  },
  peopleImg: {
    width: "22px !important",
    height: "22px !important",
    position: "sticky !important",
    minWidth: "auto !important",
    maxWidth: "none !important",
  },
}))

const PgctlSection = ({ data }: { data: QualificationItem }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Container>
        <Typography className="mb-1" variant="subtitle2">
          Teaching Qualification by Suraasa
        </Typography>
        <GradientText
          className="mb-3"
          gradient="linear-gradient(90deg, #60A5FA 0%, #3B82F6 100%)"
          variant="title2"
        >
          Professional Certificate in Teaching & Learning (PgCTL)
          <span
            style={{
              background: "white",
              backgroundClip: "inherit",
              WebkitBackgroundClip: "text",
            }}
          >
            *
          </span>
        </GradientText>
        <Typography
          className={clsx("mb-1", classes.textBody)}
          color="onSurface.50"
          variant="smallBody"
        >
          Are you an assistant teacher, teacher, or coordinator seeking growth &
          recognition? Or a new teacher looking to embark on a fast-paced career
          path? Choose Suraasa Professional graduate Certificate in Teaching &
          Learning (PgCTL), a 10-month part-time diploma, that equips you with
          global teaching skills. It imparts practical pedagogical and
          leadership strategies, which make you stand-out as an expert educator
          and comes with dual certifications that help you meet regulatory
          requirements.
        </Typography>
        <div className="grid grid-cols-2 grid-rows-2 gap-2 mt-4 mb-4 sm:mb-6 md:flex md:flex-wrap md:gap-0">
          <div className="flex items-center mr-7">
            <div
              className={clsx(classes.iconBox, "flex justify-center p-1 mr-1")}
            >
              <Image
                alt="icon"
                className={classes.peopleImg}
                height={22}
                src="/assets/landing_page/v2/icons/people-icon.svg"
                width={22}
              />
            </div>
            <div>
              <Typography variant="strongSmallBody">Learners</Typography>
              <Typography variant="smallBody">
                {data.numberOfEnrollments}
              </Typography>
            </div>
          </div>

          <div className="flex items-center mr-7">
            <div
              className={clsx(classes.iconBox, "flex justify-center p-1 mr-1")}
            >
              <GraphUp />
            </div>
            <div>
              <Typography variant="strongSmallBody">Level</Typography>
              <Typography variant="smallBody">UK Level 6</Typography>
            </div>
          </div>
          <div className="flex items-center mr-7">
            <div
              className={clsx(classes.iconBox, "flex justify-center p-1 mr-1")}
            >
              <Clock />
            </div>
            <div>
              <Typography variant="strongSmallBody">Duration</Typography>
              <Typography variant="smallBody">40 Weeks</Typography>
            </div>
          </div>
        </div>

        <CTAButton
          className={classes.bookACall}
          startAdornment={<Calendar />}
          tagClassName="text-secondary-300"
        />
      </Container>
    </div>
  )
}

export default PgctlSection
